import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Home extends Component {

	render() {

		return (
			<section className="pageContent aboutContent">
				<div className="visual"></div>
				<div className="text">
					<div className="textInner">
						<header className="pageHeader aboutHeader">
							<h1>Tierney Creative</h1>
							<h2>Digital Design Services</h2>
						</header>
						<p className="lead-in">Hello! I&rsquo;m Joe Tierney&nbsp;&ndash;&nbsp;an expert digital designer with a
						passion for UX and a sharp eye for detail. Using a highly diverse skill set, I
						excel at making beautiful and usable digital&nbsp;experiences.</p>
						<div className="actions links">
							<Link
								className="btn primary color-secondary"
								to="/portfolio">
									<span className="label-text">View My Portfolio</span>
							</Link>
							<Link
								className="btn border-only"
								to="/contact">
									<span className="label-text">Contact Me</span>
							</Link>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

export default Home;
