import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Contact from "./pages/Contact";
import SiteHeader from './components/SiteHeader';
import ScrollToTop from './utils/ScrollTop';

import './styles/main.scss';

class App extends Component {

	render() {

		const d = new Date();
		const y = d.getFullYear();

		return (
			<BrowserRouter>
				<ScrollToTop />
				<SiteHeader />
				<main role="main">
					<Routes>
						<Route index element={<Home />} />
						<Route path="/contact" element={<Contact />} />
						<Route path="/portfolio" element={<Portfolio />} />
					</Routes>
				</main>
				<footer role="contentinfo" className="siteFooter">
					<p>&copy; {y} Joseph Tierney</p>
				</footer>
			</BrowserRouter>
		);
	}
}

export default App;
