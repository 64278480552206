import React, { Component } from 'react';
import IconEnvelope from '../icons/IconEnvelope';
import IconLinkedIn from '../icons/IconLinkedIn';

class Contact extends Component {

	render() {

		return (
			<section id="contact" className="contact pageContent">
				<header className="pageHeader">
					<div className="container">
						<h1>Contact</h1>
					</div>
				</header>
				<div className="pageBody">
					<div className="container">
						<p className="lead-in">Are you looking for UX/UI design or consultation? Do you need help with Front-End development? 
						Would you like to see more examples of my&nbsp;work?</p>
						<p>If you can answer &ldquo;yes&rdquo; to any of these questions, please contact me.</p>
						<div className="actions links">
							<a className="btn primary" href="mailto:&#106;&#111;&#101;&#64;&#116;&#105;&#101;&#114;&#110;&#101;&#121;&#99;&#114;&#101;&#97;&#116;&#105;&#118;&#101;&#46;&#99;&#111;&#109;">
								<IconEnvelope/>
								<span className="label-text">Email</span>
							</a>
							<a className="btn border-only" href="https://www.linkedin.com/in/joe-tierney-creative/" target="_blank" rel="noopener noreferrer">
								<IconLinkedIn/>
								<span className="label-text">LinkedIn</span>
							</a>
						</div>
					</div>
					</div>
			</section>
		);
	}
}

export default Contact;
