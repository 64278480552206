import React from 'react';

export const projectItems = [
	{
		title: 'Multimedia Content App',
		cats: [
			'UX Design',
			'UI Design',
			'Visual Design',
			'Prototypes',
			'User Research'
		],
		dir: '360',
		xurl: 'https://itunes.apple.com/us/app/twr360/id678282090?mt=8',
		thumb: 'twr360.jpg',
		tagsite: true,
		prod: true,
		description:
			<>
				<p>Broadcast radio is the legacy of this global evangelical outfit.
				With content from hundreds of sources, this app serves as a consumption depot
				for all the audio and video content in their massive library. With so much content,
				users need the ability to filter by numerous taxonomies without being overwhelmed.
				The app also needed simple account creation/login
				workflows, as well as localization support. This
				outreach has a significant following in developing countries, so bandwidth
				restraints also had to be&nbsp;considered.</p>
				<p>For initial user feedback I wired up simple gray-on-gray
				prototypes of primary workflows, including onboarding, content browsing, and drilling
				down to specific media playback.
				Initially, the client&rsquo;s desire was for the main listing of content to be one big, long,
				lazy-loading screen. Given the bandwidth concerns, I knew that was not going to
				work. So, we landed on the idea to load a handful of records at a time
				with a &ldquo;view all&rdquo; button, per heading. The app needs to support
				multiple languages, so I designed a minimal, scrollable modal window that
				also employs a trigger at the bottom to auto-scroll the&nbsp;list.</p>
			</>,
		images: [],
		active: true,
	},
	{
		title: 'Multimedia Website',
		cats: [
			'UX Design',
			'UI Design',
			'Visual Design',
			'Branding'
		],
		dir: 'twr360-web',
		xurl: 'https://www.twr360.org/',
		thumb: 'twr360-web.jpg',
		tagsite: true,
		prod: true,
		description:
			<>
				<p>This is the marketing site associated with the mobile app above,
				with nearly all of the same media available to the user.
				The home page alone houses dozens of media files and blog posts, all 
				grouped by common taxonomies. In order to prevent these media feeds from
				getting out of hand, many sections have a rotator mechanism. In this way, the breadth and scope
				of the client's offerings can be highlighted without overwhelming uninitiated users.
				</p>
			</>,
		images: [],
		active: false
	},
	{
		title: 'Web Presence for Global Non-profit',
		cats: [
			'UX Design',
			'UI Design',
			'Visual Design',
			'Branding'
		],
		dir: 'mwc',
		xurl: 'https://www.metroworldchild.org/',
		thumb: 'mwc.jpg',
		tagsite: true,
		prod: true,
		summary: 'Disclaimer: If you click through the live project, bear in mind that my involvement in this project ended a few years ago.',
		description:
			<>
				<p>This is an example of building a new online identity on-the-fly. 
				The client&rsquo;s primary objectives here are promotion of their 
				outreach and &ndash; ultimately &ndash; financial support from caring 
				donors. One of my challenges was taking what few brand assets were 
				available and representing their mission in the most venerable manner 
				I could whip up. Aspects of Visual Design, UI design, UX Design, and 
				Copywriting all played a part in the outcome.</p>
			</>,
		images: [],
		active: false
	},
	{
		title: 'Book Publisher Site and Storefront',
		cats: [
			'UX Design',
			'UI Design',
			'Visual Design',
			'UI Development',
			'Marketing',
			'Branding'
		],
		dir: 'ap',
		xurl: 'https://www.abingdonpress.com/',
		thumb: 'apress.jpg',
		caption: '',
		tagsite: true,
		prod: true,
		description:
			<>
				<p>A book publisher under a larger umbrella wanted a clean and
				modern website through which to feature their
				particular titles (of which there are many). Providing users a simple
				and intuitive way to browse while also giving the client the means to feature
				specific books and/or categories presented both design and development
				challenges. They wanted the homepage to have&nbsp;&ldquo;movement.&rdquo; Keeping in mind how best to
				help users browse and
				purchase books, the catalog filters were approached with a
				mobile-first mindset. The desired movement on the homepage was eventually deciphered to
				mean <em>parallax</em>. A hybrid JS/CSS solution targeting various elements ended up working best, given the
				layout of the&nbsp;page(s).</p>
			</>,
		images: [],
		active: false
	},
	{
		title: 'App Configuration Admin',
		cats: [
			'User Research',
			'Wireframes',
			'Prototypes',
			'UI Design'
		],
		detailurl: '',
		xurl: '',
		thumb: 'admin-cms.jpg',
		caption: '',
		tagsite: false,
		prod: false,
		summary: '',
		description:
			<>
				<p>This project is a admin tool for configuring client instances of 
				a white-labeled, consumer facing payment scheduling app.
				With basic business requirements in hand, I and my teammates began the
				process of gathering user data. We started by conducting interviews with several members
				of the target user group. We distilled the data we collected into a handful of personas
				that best represented the spectrum of users of this new admin
				tool. The personas were valuable in pointing us in the right direction on
				design, as well as providing rationale for UI solutions to key stakeholders.</p>
				<p>As the user data was balanced with the business needs, wireframes and
				prototypes were developed. Participants from the same
				user group were brought in for moderated testing. After iterating on user flows and
				UI design, we ultimately landed on what I describe as a micro CMS.</p>
			</>,
		images: [],
		active: true,
		links: [
			{
				url: 'https://www.sketch.com/s/83696af3-20fe-45cc-b7e0-f11ed0d2e048/a/QKRY0m/play',
				text: 'Wireframe'
			},
			{
				url: 'https://www.sketch.com/s/00f67b62-7522-42b5-8135-5e84bd082ab6/a/32KQWr/play',
				text: 'Early Prototype'
			},
			{
				url: 'https://www.figma.com/proto/TcHuNEVvgc1dVm3ka6QBBL/Implementation_Tool?page-id=0%3A1&node-id=104%3A10240&viewport=174%2C348%2C0.06&scaling=min-zoom',
				text: 'UI Design'
			}
		]
	},
	{
		title: 'Evangelism Tool',
		cats: [
			'UX Design',
			'UI Design',
			'Front-end Development',
			'Visual Design',
			'Copywriting'
		],
		dir: 'tgt',
		xurl: 'https://apps.apple.com/us/app/the-god-test/id1005211267#?platform=iphone',
		thumb: 'tgt.jpg',
		tagsite: true,
		prod: true,
		description:
			<>
				<p>This is an instrument for that would-be evangelist who strikes
				up a conversation with an otherwise unconvinced soul. A
				series of questions to test the respondent&rsquo;s preparedness for the
				hereafter is meant to prompt divergent counter-arguments for the administer, based on the answers. Each question
				screen needed to come equipped with helpful hints. Access to training/educational
				materials was also required, as well as navigation linking to a variety of
				related sites and&nbsp;apps.</p>
				<p>A series of lo-fi wireframes, interactive mockups, and hallway
				testing helped me land on the UI for the test/response mechanism. The training content,
				navigation, and other content was approached using relatively common&nbsp;patterns.</p>
			</>,
		images: [],
		active: true
	},
	{
		title: 'Patient Scheduling Apps',
		cats: [
			'UI Design',
			'Visual Design',
			'User Research',
			'Prototypes',
			'Copywriting'
		],
		detailurl: '/portfolio/appointment-scheduler',
		xurl: '',
		thumb: 'scheduling.jpg',
		caption: '',
		tagsite: false,
		prod: false,
		summary: '',
		description:
			<p>In my employer's product line is a set of healthcare scheduling apps. These
			are products that offer providers within healthcare systems a way to help their
			patients and staff to manage appointments. Some of these legacy products were
			built with only desktop users in mind. As more user requests (and customer
			complaints) came in, it was decided to invest resources into mobile-first
			redesigns. I was tapped to lead the UX/UI effort, which involved product
			research, user research, wireframes, prototypes, and user testing. The result
			was a success with patients and customers alike, even helping lead to the
			signing of another large client.</p>
		,
		images: [],
		active: false,
		links: [
			{
				url: 'https://xd.adobe.com/view/a6fc7184-daaf-4499-b4aa-5b8a556baf7b-f3af/',
				text: 'Availability Range'
			}
		]
	},
	{
		title: 'VBS Marketing Site',
		cats: [
			'UX Design',
			'UI Design',
			'Front-end Development',
			'Visual Design',
			'Branding'
		],
		dir: 'cvbs',
		xurl: 'https://www.cokesburyvbs.com/',
		thumb: 'cvbs.jpg',
		tagsite: true,
		prod: true,
		description:
			<>
				<p>The purpose of this site is to highlight the publishing company's
				VBS themes currently available for purchase. While the primary landing pages
				leverage some playful branding elements, their function is still promotional/informational.
				However, each theme has its own uniquely-branded splash page built on top of a common template.
				</p>
			</>,
		images: [],
		active: false
	},
	{
		title: 'Multistep Registration Form',
		cats: [
			'UI Design',
			'Visual Design',
			'Front-end Development',
			'Prototypes'
		],
		detailurl: '/portfolio/eo-reg',
		xurl: '',
		thumb: 'eo-registration.jpg',
		caption: '',
		tagsite: true,
		prod: false,
		summary: '',
		description:
			<p>This project has been purely UI and, as of my typing this, is still ongoing.
			However, the bulk of where it's headed is fairly well-represented in the
			prototypes linked below. Our client is a travel broker specializing in
			faith-centric tours and cruises. Online registrants for these trips have
			historically been sent to a massive and impractical one-pager of jumbled form
			fields. My first step was to take inventory of all the form fields, determine
			what data points were condtional, which ones were required, etc., and then group
			them into field sets that made logical sense. Certain field groups in the flow
			will have variable options, depending on settings chosen in a prior step. As
			such, the idea of a progress-based form seemed the best approach.</p>
		,
		images: [],
		active: false,
		links: [
			{
				url: 'https://www.figma.com/proto/HThEniqj1utrGUyQ9MTxOC/EOT-RegistrationForm-(Copy)?page-id=0%3A1&node-id=1%3A2&viewport=93%2C-514%2C0.5&scaling=scale-down&starting-point-node-id=1%3A2',
				text: 'Desktop Prototype'
			},
			{
				url: 'https://www.figma.com/proto/HThEniqj1utrGUyQ9MTxOC/EOT-RegistrationForm-(Copy)?page-id=79%3A3405&node-id=79%3A4424&viewport=524%2C445%2C0.25&scaling=scale-down&starting-point-node-id=79%3A4424',
				text: 'Mobile Prototype'
			}
		]
	}
]
