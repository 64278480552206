import React from 'react';
import { NavLink } from "react-router-dom";

class SiteHeader extends React.Component {

	render() {

		return (
			<header className="siteHeader">
				<NavLink to="/" tabIndex={0} className="siteName">
					Tierney <span className="text-slash">/</span> Creative
				</NavLink>
				<nav className={"siteNav"}>
					<ul>
						<li>
							<NavLink
								to="/"
								tabIndex={0}>
									<span className={'label-text'}>Home</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								activeClass="active"
								to="/portfolio"
								tabIndex={0}>
									<span className={'label-text'}>Portfolio</span>
							</NavLink>
						</li>
						<li>
							<NavLink
								activeClass="active"
								to="/contact"
								tabIndex={0}>
									<span className={'label-text'}>Contact</span>
							</NavLink>
						</li>
					</ul>
				</nav>
			</header>
		);
	}
}

export default SiteHeader;
