import React from 'react';
import {projectItems} from '../projects';

class ProjectItem extends React.Component {
	render() {

		const projects = projectItems;
		const activeProjects = projects.filter(p => p.active);

		return (
			activeProjects.map(p => (
				<section className={'item feed-item'} key={p.dir}>
					<div className="visual">
						{p.xurl 
							? <a href={p.xurl} className={'linked-img'} rel="noopener noreferrer" target="_blank"><img src={'./assets/images/projects/thumbnails/'+p.thumb} alt={p.title}/></a> 
							: <img src={'./assets/images/projects/thumbnails/'+p.thumb} alt={p.title}/>
						}
					</div>
					<div className={'text'}>
						<h2 className={'h3 title'}>
							{p.title} {p.tagsite ? <sup>*</sup> : ''}
						</h2>
						<div className={'description'}>{p.description}</div>
						<div className={'actions'}>
							{p.xurl && <a className={'btn tertiary-invert sm'} href={p.xurl} rel="noopener noreferrer" target="_blank" aria-label={p.title}><span className={'link-text label-text'}>Live Project</span></a>}
							{p.links && p.links.map(l => (
								<a className={'btn tertiary-invert sm'} key={l.url} href={l.url} rel="noopener noreferrer" target="_blank" aria-label={l.text}><span className={'link-text label-text'}>{l.text}</span></a>
							))}
						</div>
						{p.summary && <p className={'summary text-alt'}><small>{p.summary}</small></p>}
					</div>
				</section>
			))
		);
	}
}

export default ProjectItem;