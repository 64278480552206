import React from 'react';
import { Link } from "react-router-dom";
import ProjectItem from '../components/ProjectItem.js';

class Portfolio extends React.Component {

	render() {

		return (
			<section className="projects pageContent">
				<header className="pageHeader">
					<div className="container">
						<h1>Portfolio</h1>
						<p className='lead-in'>Below is a small sampling of projects showcasing my skills and experience. 
						I have many more examples
						which I&rsquo;m simply not at liberty to promote here.&nbsp;
						<Link to="/contact">Please&nbsp;contact&nbsp;me</Link>&nbsp;
						to see more.</p>
					</div>
				</header>
				<div className="pageBody">
					<div className="container">
						<div className="feed projectsFeed flex rows">
							<ProjectItem />
						</div>
					</div>
					<section className='calloutMore'>
						<div className="container">
							<p className="disclaimer note no-max-width"><sup>*</sup> These examples are meant only to demonstrate my
							skills and expertise. They are not intended to endorse any particular ideology.</p>
						</div>
					</section>
				</div>
			</section>
		);
	}
}

export default Portfolio;
